import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Button, DialogActions } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import React from 'react'
import { analytics } from '../firebase-config'
import Ion from './Ion'

function DonateDialog({ open, setOpen, donateLink }: { open: boolean, setOpen: Function, donateLink: string }) {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Donate"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Care to send me a gift as a thank you for the work showcased here?"}
        </DialogContentText>
        <div style={{ marginTop: "1em", display: "flex", flexDirection: "column", gap: "1em" }}>
          <Typography variant="body1" gutterBottom>
            I put horny stuff on the internet free of charge, because I like making you feel good! If you would like to do something small to also help me feel good, please consider checking out my Throne page! Absolutely no pressure, but just know it's there if that's something you're interested in!
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
            setOpen(false);
            logEvent(analytics, 'clickedNavBarGiftDialogCancelBtn');
        }}>Sorry mate, not right now!</Button>
        <Button onClick={() => {
            logEvent(analytics, 'clickedNavBarGiftDialogDONATEBUTTON');
            setOpen(false);
        }}
        href={donateLink} target="_blank" rel="noreferrer" variant="contained"><Ion name={"gift"} />Take me to your wishlist!</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DonateDialog