export type ArtSourceType = "e621" | "Cap on Twitter" | "Twitter" | "FurAffinity" | "Other Link"; // string literal types

export type ArtSource = {
    type: ArtSourceType; //"e621" | "Cap on Twitter" | "Twitter" | "FurAffinity" | "Other Link"; // string literal types
    link: string;
    customLabel?: string;
    sameTab?: boolean;
}

export type Caption = {
    imageUrl: string;
    sources: Array<ArtSource>;
    date: string;
    title: string;
    description?: string;
    unlisted?: boolean;
    tags?: Array<string>;
}

export type CaptionDataRow = Caption & {id: string};

export const SampleCaption: Caption = {
    imageUrl: "https://d3ui957tjb5bqd.cloudfront.net/uploads/images/4/41/41981.pinterest.jpg?fmt=webp&1482252866",
    sources: [
        { type: "Other Link", link: "https://www.google.com/search?q=clown+emoji" }
    ],
    date: "1669266660000", //Date.now().valueOf().toString()
    title: "Clown Cap",
    description: "this is about a clown",
};

export const SampleCaptionDataRow: CaptionDataRow = {...SampleCaption, id: "FIXMEIDFIXMEIDFIXMEID"}

// experimental
export type CapView = {
    // capId: string; (going to setDoc w/ capId as the new id)
    viewCount: number;
}

export type CapViewDataRow = CapView & {id: string};