import { Alert, Button, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { logEvent } from 'firebase/analytics';
import { collection, getDocs, orderBy, query, where, limit, startAfter } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { analytics, db } from '../firebase-config';
import { Caption, CaptionDataRow } from '../interfaces' // eslint-disable-line @typescript-eslint/no-unused-vars
import Page from '../Templates/Page'

function Home({ isAuth, signUserOut, taggedView }: { isAuth: boolean, signUserOut: Function, taggedView?: boolean }) {
  const [captions, setCaptions] = useState<Array<CaptionDataRow | any>>([]);
  const [captionsDocsRaw, setCaptionsDocsRaw] = useState<Array<any>>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [noMore, setNoMore] = useState<boolean>(false);
  const { tags } = useParams();

  const limitPosts = 5;

  const runUpdate = () => {
    const genQuery = () => {
      const captionsCollectionRef = collection(db, "caps");
      if(taggedView && tags){
        return query(captionsCollectionRef, where("tags", "array-contains", tags.split("+")[0]), orderBy("unlisted"), orderBy("date", "desc"), where("unlisted", "!=", true), limit(limitPosts));
      }
      return query(captionsCollectionRef, orderBy("unlisted"), orderBy("date", "desc"), where("unlisted", "!=", true), limit(limitPosts));
    }
    const getCaptions = async () => {
      const q = genQuery();
      const querySnapshot = await getDocs(q);
      setCaptionsDocsRaw(querySnapshot.docs);
      let d = querySnapshot.docs.map((doc) => ({ ...doc.data() as Caption, id: doc.id }))
      d.sort((a, b) => b.date.localeCompare(a.date))
      setCaptions(d);
      setPending(false);
    }
    getCaptions();
  };

  const getMore = () => {
    let analObject: any = {home_or_tagged: (taggedView && tags) ? "tagged" : "home"};
    if(analObject.home_or_tagged === "tagged"){
      analObject.tags = tags;
    }
    logEvent(analytics, 'clicked_get_more', analObject);
    const genQuery = () => {
      const captionsCollectionRef = collection(db, "caps");
      let lastId = (captionsDocsRaw[captionsDocsRaw.length-1]);
      if(taggedView && tags){
        return query(captionsCollectionRef, where("tags", "array-contains", tags.split("+")[0]), orderBy("unlisted"), orderBy("date", "desc"), startAfter(lastId), where("unlisted", "!=", true), limit(limitPosts));
      }
      return query(captionsCollectionRef, orderBy("unlisted"), orderBy("date", "desc"), startAfter(lastId), where("unlisted", "!=", true), limit(limitPosts));
    }
    const getMoreRunner = async () => {
      try {
        // const captionsCollectionRef = collection(db, "caps");
        // let lastId = (captionsDocsRaw[captionsDocsRaw.length-1]);
        // const next_q = query(captionsCollectionRef, orderBy("unlisted"), orderBy("date", "desc"), startAfter(lastId), where("unlisted", "!=", true), limit(limitPosts));
        const next_q = genQuery();
        const querySnapshot = await getDocs(next_q);
        setCaptionsDocsRaw(querySnapshot.docs);
        let d = querySnapshot.docs.map((doc) => ({ ...doc.data() as Caption, id: doc.id }))
        d.sort((a, b) => b.date.localeCompare(a.date));
        let newCaptions = [...captions];
        d.forEach((i: CaptionDataRow) => newCaptions.push(i));
        setCaptions(newCaptions);
      } catch {
        let lastId = (captionsDocsRaw[captionsDocsRaw.length-1]);
        if(lastId === undefined){
          setNoMore(true);
        }
      }
    }
    getMoreRunner();
  }

  useEffect(() => {
    runUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);
  useEffect(() => {
    if(taggedView && tags){
      logEvent(analytics, 'visit_cap_site_tag_page', {tags: tags});
    } else {
      logEvent(analytics, 'visit_cap_site_home');
    }
  }, [taggedView, tags])
  return (
    <Page isAuth={isAuth} signUserOut={signUserOut}>
      <Typography variant="h1">Captions{taggedView ? ` tagged ${tags?.split("+")[0]} ${(tags && tags.split("+").length > 1) ? `(ignored additional tags [${tags.split("+").slice(1).join(", ")}] because multiple tags area not (yet) supported). Do you like how big and obnoxious I made the error message? Is this what you were hoping for? I hope so. I love giant error messages that really get in my way. It's the best.` : ""}` : ""}</Typography>
      <Typography variant="h4">by <a href="https://oddpa.ws" rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "inherit" }}>OddPawsX</a></Typography>
      <div style={{ marginBottom: "1em"}} />
      {/* 2022-12-13: alert for "please don't repost" */}
      {true && <>
        <Alert severity='warning'>Hey y'all. If you're going to <a style={{ color: "inherit" }} href="https://twitter.com/GoonEvenMore/status/1597700704541741056" target="_blank" rel="noreferrer">repost my captions</a>, the <i>least</i> you could do is @ me in them. <a style={{ color: "inherit" }} href="https://twitter.com/OddPawsX" target="_blank" rel="noreferrer">@OddPawsX</a> on Twitter, for reference.</Alert>
        <div style={{ marginBottom: "1em"}} />
      </>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em"
        }}
      >
        {pending ? "Loading..." : <Grid container>
          {captions.map((cap: CaptionDataRow, idx: number) => {
            return (
              <div key={`cap-idx-${idx}`}>
                <Grid item>
                  <Link to={`/cap/${cap.id}`}>
                    <Paper
                      sx={{
                        padding: "1em",
                        margin: "1em"
                      }}
                    >
                      <img src={cap.imageUrl} style={{ width: "100px", height: "auto" }} alt="a caption" />
                    </Paper>
                  </Link>
                </Grid>
              </div>
            )
          })}
        </Grid>}
        {!noMore && <Button variant="contained" color="secondary" onClick={getMore}>Load More</Button>}
        <div style={{ marginBottom: "1em" }} />
      </Box>
    </Page>
  )
}

export default Home
