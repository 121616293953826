import { Box, Typography, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { ArtSource, CaptionDataRow } from '../interfaces';
import E621Icon from './E621Icon';
import Ion from './Ion';

function CaptionShare({ data }: { data: CaptionDataRow }) {
  const [copyPermalinkClicked, setCopyPermalinkClicked] = useState<boolean>(false);
  let defaultChipLabel = `Click to copy permalink`;
  const handleCopyClick = () => { setCopyPermalinkClicked(true) };
  const handleMouseLeave = () => { setCopyPermalinkClicked(false) };
  const [hasCapOnTwitter, setHasCapOnTwitter] = useState<boolean>(false);
  const [hasCapOnTwitterLink, setHasCapOnTwitterLink] = useState<string>("");
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1em"
        }}
      >
        <Typography>Share: </Typography>
        <Tooltip title={copyPermalinkClicked ? "Copied!" : defaultChipLabel} >
          <span style={{ cursor: "pointer" }} onClick={() => {
            navigator.clipboard.writeText(`${new URL(window.location.href).origin}/cap/${data.id}`);
            handleCopyClick();
          }}
            onMouseLeave={handleMouseLeave}
          >
            <Ion name="share-social" fontSize='1.5em' />
          </span>
        </Tooltip>
        {data.sources.map((source: ArtSource, idx: number) => {
          if (!hasCapOnTwitter && source.type === "Cap on Twitter") {
            setHasCapOnTwitter(true);
            setHasCapOnTwitterLink(source.link);
          }
          return (
            <span key={`source-link-${idx}`}>
              <Tooltip title={source.customLabel || source.type} >
                <span>
                  <a href={source.link} target={!source.sameTab ? "_blank" : undefined} rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
                    {/* {source.type === "e621" && <><Ion name="file-tray-full" fontSize='1.5em' /></>} */}
                    {source.type === "e621" && <><E621Icon /></>}
                    {/* {(source.type === "Twitter" || source.type === "Cap on Twitter") && <><Ion name="logo-twitter" fontSize='1.5em' /></>} */}
                    {source.type === "Twitter" && <><Ion name="logo-twitter" fontSize='1.5em' /></>}
                    {source.type === "Cap on Twitter" && <><Ion name="logo-twitter" fontSize='1.5em' /><Typography variant="caption">{"<-- Retweet this Caption Please!"}</Typography></>}
                    {source.type === "FurAffinity" && <><Ion name="paw" fontSize='1.5em' /></>}
                    {source.type === "Other Link" && <><Ion name="link-outline" fontSize='1.5em' /></>}
                  </a>
                </span>
              </Tooltip>
            </span>
          )
        })}
      </Box>
      {hasCapOnTwitter === true && hasCapOnTwitterLink !== "" && <Box>
        <Typography sx={{ fontStyle: "italic" }} gutterBottom>
          Do you have a horny Twitter account? &nbsp;
          <a style={{ color: "inherit" }} target="_blank" rel="noreferrer" href={`https://twitter.com/intent/retweet?tweet_id=${hasCapOnTwitterLink.split("/").at(-1)}`}>
            Please consider retweeting this caption to spread the horny~
          </a>
        </Typography>
      </Box>}
    </div>
  )
}

export default CaptionShare