import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import NavAppBar from '../Components/NavAppBar';

import "./Page.css";



type Props = {
  children?: React.ReactNode,
  title?: string,
  container?: boolean,
  isAuth?: boolean,
  signUserOut?: Function,
}

function Page(props: Props) {
  useEffect(() => {
    if (props.title && props.title?.toString().length > 0 && !props.title.toString().includes("undefined")) {
      document.title = props.title + " | opxCAPS" || "opxCAPS";
    } else {
        document.title = "opxCAPS";
    }
  }, [props.title]);
  return (
    <div>
      <NavAppBar isAuth={props.isAuth || false} signUserOut={props.signUserOut} />
      {props.container === false ? props.children : <Container>
        {props.children}
      </Container>}
    </div>
  )
}

export default Page