import { Dialog, DialogTitle, DialogContent, DialogContentText, List, ListItem, IconButton, ListItemText, Divider, ListItemButton, DialogActions, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import EditTag from './EditTag';
import AddTag from './AddTag';

function EditTags({ open, setOpen, tags, updateTags }: {open: boolean, setOpen: Function, tags: Array<any>, updateTags: Function}) {
  const [localTags, setLocalTags] = useState<Array<string>>(tags);
  const [addTagOpen, setAddTagOpen] = useState<boolean>(false);
  const [editTagOpen, setEditTagOpen] = useState<boolean>(false);
  const [oldTagHolder, setOldTagHolder] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
    setLocalTags(tags)
  };

  const handleSaveAndClose = () => {
    updateTags(localTags);
    handleClose();
  };

  const removeTag = (tagToRemove: string) => {
    let newTags = [...localTags];
    let idxOfTagToRemove = newTags.indexOf(tagToRemove);
    if (idxOfTagToRemove !== -1) {
      newTags.splice(idxOfTagToRemove, 1);
    }
    setLocalTags(newTags);
  };

  useEffect(() => {
    setLocalTags(tags);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalCapTags = (newTag: string) => {
    let newTags = [...localTags];
    newTags.push(newTag);
    setLocalTags(newTags);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Tags"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit tags for the caption.
          </DialogContentText>
          <List>
            {localTags.map((tag: string, idx: number) => (
              <ListItem
                key={`tag-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => { setOldTagHolder(tag); setEditTagOpen(true) }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => { removeTag(tag) }}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={tag}
                />
              </ListItem>
            ))}
            {localTags.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => { setAddTagOpen(true) }}>
                <ListItemText primary="Add New Tag" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditTag open={editTagOpen} setOpen={setEditTagOpen} oldTag={oldTagHolder} handleEditTag={(oldTag: string, newTag: string) => {
        let idxToUpdate = localTags.indexOf(oldTag);
        localTags[idxToUpdate] = newTag;
      }} />
      <AddTag open={addTagOpen} setOpen={setAddTagOpen} addTag={handleUpdateLocalCapTags} />
    </div>
  )
}

export default EditTags