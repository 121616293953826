import { Alert, Box, Chip, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { CaptionDataRow, CapView, CapViewDataRow, SampleCaptionDataRow } from '../../interfaces';
import Page from '../../Templates/Page'

import dayjs from 'dayjs';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { analytics, db } from '../../firebase-config';
import CaptionShare from '../../Components/CaptionShare';
import { logEvent } from 'firebase/analytics';

function Caption({ capIdSpecified, isAuth, signUserOut }: { capIdSpecified?: boolean, isAuth: boolean, signUserOut: Function }) {
  const { capId } = useParams();
  const [capData, setCapData] = useState<CaptionDataRow | null | any>();
  const [capLoading, setCapLoading] = useState<boolean>(true);
  const [viewCount, setViewCount] = useState<number|undefined>();

  const runUpdate = () => {
    const getCaptions = async () => {
      const captionsCollectionRef = collection(db, "caps");
      getDoc(doc(captionsCollectionRef, capId)).then((res: any) => {
        setCapData(res.data());
        setCapLoading(false);
        // experimental: increment view count (or create if not exists)
        const capViewCountCollectionRef = collection(db, "viewCounts");
        getDoc(doc(capViewCountCollectionRef, capId)).then((res: any) => {
          try {
            let oldViewData: CapViewDataRow = res.data();
            if(oldViewData){
              let newViewData: CapView = {...oldViewData};
              newViewData.viewCount = newViewData.viewCount + 1;
              setDoc(doc(capViewCountCollectionRef, capId), newViewData);
              setViewCount(newViewData.viewCount);
            } else {
              let newViewData: CapView = {
                viewCount: 1
              };
              setDoc(doc(capViewCountCollectionRef, capId), newViewData);
              setViewCount(newViewData.viewCount);
            }
          } catch (err) {
            console.error("Could not update view count! Oops!")
          }
        })
      }).catch((err) => {
        console.error(err);
        setCapLoading(false);
      })
    };
    getCaptions();
  };

  useEffect(() => {
    if (capId === "FIXMEIDFIXMEIDFIXMEID" && false) {
      // render the test item for testing
      setCapData(SampleCaptionDataRow);
      setCapLoading(false);
    } else {
      // get from db
      try {
        runUpdate()
      } catch (e) {
        // console.error(e);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capId]);

  useEffect(() => {
    logEvent(analytics, 'view_caption', {capId: capId});
  }, [capId])

  return (
    <Page isAuth={isAuth} signUserOut={signUserOut}>
      <Link to="/" style={{ color: "inherit" }}>{"<-- Go Back"}</Link>
      <div style={{ marginBottom: "1em" }} />
      {capIdSpecified ? <>
        {/* check if loading */}
        {capLoading ? <>
          {/* still loading */}
          Loading...
        </> : <>
          {/* cap exists or not */}
          {capData ? <>
            {/* exists */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // gap: "1em"
              }}
            >
              {/* 2022-12-13: alert for "please don't repost" */}
              {true && <>
                <Alert severity='warning'>Hey y'all. If you're going to <a style={{ color: "inherit" }} href="https://twitter.com/GoonEvenMore/status/1597700704541741056" target="_blank" rel="noreferrer">repost my captions</a>, the <i>least</i> you could do is @ me in them. <a style={{ color: "inherit" }} href="https://twitter.com/OddPawsX" target="_blank" rel="noreferrer">@OddPawsX</a> on Twitter, for reference.</Alert>
                <div style={{ marginBottom: "1em"}} />
              </>}
              <Typography variant="h3">{capData.title}</Typography>
              {capData.unlisted && <div><Chip label={"Unlisted"} /></div>}
              {capData.description && <Typography variant="caption">{capData.description}</Typography>}
              <Typography variant="caption">{dayjs(parseInt(capData.date,10)).format()}</Typography>
              <Typography variant="caption">{viewCount ? `Views: ${viewCount}` : ""}</Typography>
              <div style={{ marginBottom: "1em" }} />
              {/* sharing */}
              <CaptionShare data={{...capData, id: capId}} />
              <div>{capData.tags && capData.tags.length > 0 && <div>Tags: {capData.tags.map((tag: string, idx: number) => (<Tooltip onMouseOver={() => {logEvent(analytics, 'hover_over_tag_btn', {currentCapId: capId, tag: tag})}} key={`tag-${idx}`} title={`View caps tagged ${tag}`}><Link to={`/tagged/${encodeURIComponent(tag)}`} onClick={() => {logEvent(analytics, 'click_tag_btn', {currentCapId: capId, tag: tag})}}><Chip sx={{ cursor: "pointer" }} label={`#${tag}`} /></Link></Tooltip>))}</div>}</div>
              <div style={{ marginBottom: "1em" }} />
              <Typography variant="body1">Did you nut to this? <a onClick={() => {
                logEvent(analytics, 'clicked_nuttrackr_link', {capId: capId});
              }} style={{ color: "inherit" }} rel="noreferrer" target="_blank" href={`https://nuttrackr.com/dash/nut?referral=opxCAP_${capId}`}>Track it in nutTRACKR~!</a></Typography>
              <div style={{ marginBottom: "1em" }} />
              <img src={capData.imageUrl} alt="Captioned art" style={{ width: "100%" }} />
              <div style={{ marginBottom: "1em" }} />
            </Box>


          </> : <>
            {/* doesn't exist */}
            Caption at this URL does not exist. Sorry!
          </>}
        </>}
      </> : "Please Specify a Caption ID"}
    </Page>
  )
}

export default Caption