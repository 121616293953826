import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
// icons
import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';

import "./NavAppBar.css";
import Ion from './Ion';
import DonateDialog from './DonateDialog';
import HeadsUpFrequentViewer from './HeadsUpFrequentViewer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';

const drawerWidth = 240;

export default function NavAppBar({isAuth, signUserOut}: {isAuth: boolean, signUserOut: any}) {

  const bean = localStorage.getItem('bean');
  const [numTimesBeen, setNumTimesBeen] = useState<number>(bean ? parseInt(bean) : 0);
  const [showDonate, setShowDonate] = useState<boolean>(numTimesBeen >= 10);
  useEffect(() => {
    if (bean) {
      let newBean = (parseInt(bean) + 1).toString();
      localStorage.setItem("bean", newBean);
      setNumTimesBeen(numTimesBeen + 1);
      if(numTimesBeen >= 10){
        setShowDonate(true)
      }
    } else {
      localStorage.setItem("bean", "1");
      setNumTimesBeen(1);
    }
    logEvent(analytics, 'timesBeen', {timesBeenValue: numTimesBeen});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const [showDonateDialog, setShowDonateDialog] = useState<boolean>(false);
  const donateLink = "https://throne.me/u/oddpawsx/wishlist";

  const [showFrequentDonate, setShowFrequentDonate] = useState<boolean>(false);
  useEffect(() => {
    if(!isAuth && numTimesBeen > 10 && numTimesBeen % 20 === 0 && numTimesBeen % 2 === 0){
      setShowFrequentDonate(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  }, [isAuth, numTimesBeen]);

  const navPages = [
    {route: '/', text: 'Home', icon: <HomeIcon />},
  ];

  const mgmtNavPages = [
    {route: '/mgmt/caps', text: 'Manage Caps', icon: <GradingIcon />}
  ];

  const [open, setOpen] = React.useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawerOpen = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: "1em" }}>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} enableColorOnDark>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" className={"header-link"}>
              opxCAPS
            </Link>
          </Typography>
          {!isAuth && showDonate && <><Button onMouseOver={() => {
            logEvent(analytics, 'hoveredOverNavBarGiftDialogButton');
          }} onClick={() => {
            setShowDonateDialog(true);
            logEvent(analytics, 'clickedNavBarGiftDialogButton');
          }} variant="contained" color="secondary"><Ion name={"gift"} />Like what you're seeing~?</Button></>}
          {
            !isAuth ? 
              <span>
                {/* <Button component={Link} to="/login" color="inherit">Login</Button> */}
              </span>
            :
              <span>
                <Button onClick={signUserOut} color="inherit">Log Out</Button>
              </span>
          }
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        open={open}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Divider />
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {navPages.map((navItem, index) => (
              <ListItem key={navItem.text} disablePadding>
                <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={navItem.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* <Divider /> */}
          {isAuth && mgmtNavPages.length > 0 && (<div>
            <Divider />
            <List>
              <ListItem key="mgmt-title">
                {/* <Typography>Pages</Typography> */}
              </ListItem>
              {mgmtNavPages.map((navItem: any, index: any) => (
                <ListItem key={navItem.text} disablePadding>
                  <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                    <ListItemIcon>
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>)}
        </Box>
      </SwipeableDrawer>
      <DonateDialog open={showDonateDialog} setOpen={setShowDonateDialog} donateLink={donateLink} />
      <HeadsUpFrequentViewer open={showFrequentDonate} setOpen={setShowFrequentDonate} donateLink={donateLink} />
    </Box>
  );
}
