import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

function AddTag({ open, setOpen, addTag }: { open: boolean, setOpen: Function, addTag: Function }) {
  const [tag, setTag] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newTag: string = tag;
    addTag(newTag);
    handleClose();
  };

  useEffect(() => {
    setTag("");
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Tag"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add New Tag
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-tag"
              label="Tag"
              placeholder='booty'
              onChange={(event) => { setTag(event.target.value) }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddTag