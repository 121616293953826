import { Dialog, DialogTitle, DialogContent, DialogContentText, List, ListItem, IconButton, ListItemText, Divider, ListItemButton, DialogActions, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ArtSource } from '../interfaces';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import EditLink from './EditLink';
import AddLink from './AddLink';

function EditLinks({ open, setOpen, links, updateLinks }: {open: boolean, setOpen: Function, links: Array<any>, updateLinks: Function}) {
  const [localLinks, setLocalLinks] = useState<Array<ArtSource>>(links);
  const [addLinkOpen, setAddLinkOpen] = useState<boolean>(false);
  const [editLinkOpen, setEditLinkOpen] = useState<boolean>(false);
  const [oldLinkHolder, setOldLinkHolder] = useState<ArtSource>({
    type: 'Other Link',
    link: '',
  });

  const handleClose = () => {
    setOpen(false);
    setLocalLinks(links)
  };

  const handleSaveAndClose = () => {
    updateLinks(localLinks);
    handleClose();
  };

  const removeLink = (linkToRemove: ArtSource) => {
    let newLinks = [...localLinks];
    let idxOfLinkToRemove = newLinks.indexOf(linkToRemove);
    if (idxOfLinkToRemove !== -1) {
      newLinks.splice(idxOfLinkToRemove, 1);
    }
    setLocalLinks(newLinks);
  };

  useEffect(() => {
    setLocalLinks(links);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalCapLinks = (newLink: ArtSource) => {
    let newLinks = [...localLinks];
    newLinks.push(newLink);
    setLocalLinks(newLinks);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Links"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit links for the caption source.
          </DialogContentText>
          <List>
            {localLinks.map((lnk: ArtSource, idx: number) => (
              <ListItem
                key={`link-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => { setOldLinkHolder(lnk); setEditLinkOpen(true) }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => { removeLink(lnk) }}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={lnk.type}
                  secondary={lnk.link}
                />
              </ListItem>
            ))}
            {localLinks.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => { setAddLinkOpen(true) }}>
                <ListItemText primary="Add New Link" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditLink open={editLinkOpen} setOpen={setEditLinkOpen} oldLink={oldLinkHolder} handleEditLink={(oldLink: ArtSource, newLink: ArtSource) => {
        let idxToUpdate = localLinks.indexOf(oldLink);
        localLinks[idxToUpdate] = newLink;
      }} />
      <AddLink open={addLinkOpen} setOpen={setAddLinkOpen} addLink={handleUpdateLocalCapLinks} />
    </div>
  )
}

export default EditLinks