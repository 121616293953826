import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArtSource, ArtSourceType } from "../interfaces";

function AddLink({ open, setOpen, addLink }: { open: boolean, setOpen: Function, addLink: Function }) {
  const [type, setType] = useState<ArtSourceType>("Other Link");
  const [link, setLink] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newLink: ArtSource = {
      type: type,
      link: link
    };
    addLink(newLink);
    handleClose();
  };

  useEffect(() => {
    setType("Other Link");
    setLink("");
  }, [open]);

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as ArtSourceType);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add New Link
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FormControl fullWidth>
              <InputLabel id="simple-select-label">Type</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={type}
                label="Type"
                onChange={handleChangeType}
              >
                <MenuItem value={"Other Link"}>Other Link</MenuItem>
                <MenuItem value={"FurAffinity"}>FurAffinity</MenuItem>
                <MenuItem value={"Twitter"}>Twitter</MenuItem>
                <MenuItem value={"e621"}>e621</MenuItem>
                <MenuItem value={"Cap on Twitter"}>Cap on Twitter</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              id="new-link-url"
              label="URL"
              placeholder='https://twitter.com/'
              onChange={(event) => { setLink(event.target.value) }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddLink