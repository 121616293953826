import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useState } from 'react'
import { ArtSource, Caption, CaptionDataRow } from '../interfaces';
import dayjs from 'dayjs';
import EditLinks from './EditLinks';
import { uuidv4 } from '@firebase/util';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase-config';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from '@mui/lab';
import EditTags from './EditTags';

function CaptionDialog({ open, setOpen, dialogTitle, dialogDescription, loadedCapForEditing, setLoadedCapForEditing, editCapHandler, addCapHandler, runUpdate }: { open: boolean, setOpen: Function, dialogTitle?: string, dialogDescription?: string, loadedCapForEditing?: CaptionDataRow, setLoadedCapForEditing?: Function, editCapHandler?: Function, addCapHandler?: Function, runUpdate?: Function }) {
  const [title, setTitle] = useState<string>(loadedCapForEditing?.title || "")
  const [description, setDescription] = useState<string>(loadedCapForEditing?.description || "")
  const [date, setDate] = React.useState(dayjs(loadedCapForEditing ? parseInt(loadedCapForEditing.date, 10) : undefined));
  const [sources, setSources] = useState<Array<ArtSource>>(loadedCapForEditing?.sources || []);
  const [editLinksOpen, setEditLinksOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>(loadedCapForEditing?.imageUrl || "");
  const [unlisted, setUnlisted] = useState<boolean|undefined>(loadedCapForEditing?.unlisted);
  const [tags, setTags] = useState<Array<string>|undefined>(loadedCapForEditing?.tags);
  const [editTagsOpen, setEditTagsOpen] = useState<boolean>(false);

  const handleClose = (cancel: boolean) => {
    setOpen(false);
    if(setLoadedCapForEditing){}
      // if(!cancel){
      setTitle(loadedCapForEditing?.title || "");
      setDescription(loadedCapForEditing?.description || "");
      setDate(dayjs(loadedCapForEditing ? parseInt(loadedCapForEditing.date, 10) : undefined));
    // }
  };
  const handleSaveAndClose = () => {
    
    if(loadedCapForEditing) { // stupid typescript... grumble grumble grumble... 
      let newCapData: CaptionDataRow = {...loadedCapForEditing};
      newCapData.title = title;
      newCapData.description = description.length > 0 ? description : "";
      newCapData.date = date.valueOf().toString();
      newCapData.sources = sources;
      newCapData.imageUrl = imageUrl;
      newCapData.unlisted = unlisted;
      newCapData.tags = tags || [];
      if(editCapHandler){
        editCapHandler(newCapData, runUpdate);
      }
      handleClose(false);
    } else if(addCapHandler) {
      let newCapData: Caption = {
        title: title,
        sources: sources,
        imageUrl: imageUrl,
        description: description,
        date: date.valueOf().toString(),
        unlisted: unlisted,
        tags: tags || [],
      };
      addCapHandler(newCapData, runUpdate);
      handleClose(false);
    }
  }

  const handleUpdateLinks = (newLinks: Array<ArtSource>) => {
    setSources(newLinks);
  };

  const handleUpdateTags = (newTags: Array<string>) => {
    setTags(newTags);
  };

  const [selectedImgFile, setSelectedImgFile] = useState<any>();
  const [IsImgFilePicked, setIsImgFilePicked] = useState<boolean>(false);
  const [isUploadClicked, setIsUploadClicked] = useState<boolean>(false);
  const [isFileUploadDone, setIsFileUploadDone] = useState<boolean>(false);

  const changeImgHandler = (event: any) => {
    setSelectedImgFile(event.target.files[0]);
    setIsFileUploadDone(false);
    setIsImgFilePicked(true);
  };

  const handleImgSubmission = () => {
    const doUpload = () => {
      // disable upload button while upload happening
      setIsUploadClicked(true);
      let uuidprefix = uuidv4();
      let lastDot = selectedImgFile.name.lastIndexOf('.');
      // let fileName = selectedImgFile.name.substring(0, lastDot);
      let ext = selectedImgFile.name.substring(lastDot + 1);

      let uploadObj = ref(
        storage,
        `caps/${uuidprefix}.${ext}`
      );
      uploadBytes(uploadObj, selectedImgFile).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(uploadObj).then((res) => {
          setImageUrl(res);
          setIsFileUploadDone(true);
          setIsUploadClicked(false);
        });
      });
    };
    doUpload();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialogTitle || "Manage Caption Post"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogDescription || ""}
        </DialogContentText>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <TextField
            required
            id="cap-title"
            label="Cap Title"
            placeholder='Some Title Here'
            value={title || ""}
            onChange={(event) => { setTitle(event.target.value) }}
          />
          <TextField
            id="cap-desc"
            multiline
            label="Cap Description (optional)"
            placeholder='Some Description Here'
            value={description || ""}
            onChange={(event) => { setDescription(event.target.value) }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date Created"
              value={date}
              onChange={(n: any) => {setDate(n)}}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Typography>Links:</Typography>
          {sources.map((src: ArtSource, idx: number) => {
            return (
              <a key={`link-chip-${idx}`} href={src.link} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}><Chip sx={{ cursor: "pointer" }} label={src.type} /></a>
            )
          })}
          <Button onClick={() => {setEditLinksOpen(true)}}>Edit Links</Button>
          {imageUrl && imageUrl.length > 0 && <img src={imageUrl} alt={"Preview"} style={{ height: "200px", width: "auto", objectFit: "scale-down"}} />}
            <input type="file" name="file" onChange={changeImgHandler} />
            <LoadingButton
              loading={isUploadClicked}
              disabled={!IsImgFilePicked || isFileUploadDone}
              loadingPosition="start"
              startIcon={<FileUploadIcon />}
              // variant="outlined"
              onClick={handleImgSubmission}
            >
              Upload
            </LoadingButton>
            <Typography>Tags:</Typography>
            {tags && <div>
              {tags.map((tag: string, idx: number) => {
                return (
                  <Chip key={`tag-chip-${idx}`} label={`#${tag}`} />
                )
              })}
            </div>}
            <Button onClick={() => {setEditTagsOpen(true)}}>Edit Tags</Button>
            <FormGroup>
              <FormControlLabel control={<Checkbox
                checked={unlisted}
                onChange={(event: { target: { checked: boolean; }; }) => {setUnlisted(event.target.checked)}}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Unlisted?" />
            </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {handleClose(false)}}>Cancel</Button>
        <Button
          onClick={() => {handleSaveAndClose()}}
          autoFocus
          disabled={!(imageUrl.length !== 0 && sources.length !== 0 && title.length !== 0)}
        >
          {!loadedCapForEditing ? "Add" : "Save"}
        </Button>
      </DialogActions>
      <EditLinks open={editLinksOpen} setOpen={setEditLinksOpen} links={sources} updateLinks={handleUpdateLinks} />
      <EditTags open={editTagsOpen} setOpen={setEditTagsOpen} tags={tags || []} updateTags={handleUpdateTags} />
    </Dialog>
  )
}

export default CaptionDialog