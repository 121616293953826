import React from 'react'
import e621_favicon from "../images/e621_favicon.png";

function E621Icon() {
  return (
    <span>
      {/* e621 */}
      <img src={e621_favicon} alt="e621" style={{ height: "1.5em" }} />
    </span>
  )
}

export default E621Icon