import { logEvent } from 'firebase/analytics';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute, { ProtectedRouteProps } from './Components/ProtectedRoute';
import { analytics, auth } from './firebase-config';
import Home from './Pages/Home';
import Login from './Pages/Login';
import ManageCaps from './Pages/mgmt/ManageCaps';
import Caption from './Pages/view/Caption';

function Routing() {
  const [isAuth, setIsAuth] = useState<boolean>(JSON.parse(localStorage.getItem('isAuth') || "false") === true);
  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('isAuth');
      setIsAuth(false);
      window.location.pathname = "/";
    });
  };
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: isAuth,
    authenticationPath: '/',
  };
  useEffect(() => {
    logEvent(analytics, 'visit_cap_site');
  }, [])
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home isAuth={isAuth} signUserOut={signUserOut} />
    },
    {
      path: "/tagged/:tags",
      element: <Home isAuth={isAuth} signUserOut={signUserOut} taggedView />
    },
    {
      path: "/cap",
      element: <Caption isAuth={isAuth} signUserOut={signUserOut} />
    },
    {
      path: "/cap/:capId",
      element: <Caption capIdSpecified isAuth={isAuth} signUserOut={signUserOut} />
    },
    {
      path: "/astra/login",
      element: <Login isAuth={isAuth} setIsAuth={setIsAuth} />
    },
    // <Route path="/mgmt/characters" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ManageCharacters isAuth={isAuth} showNSFW={showNSFW} />} />} />
    {
      path: "/mgmt/caps",
      element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={<ManageCaps isAuth={isAuth} signUserOut={signUserOut} />} />
    },
    // {
    //   path: "*",
    //   element: <NotFound404 />,
    // }
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default Routing