import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Page from '../Templates/Page';

import { analytics, auth, db, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';

function Login({ isAuth, setIsAuth }: { isAuth: boolean, setIsAuth: React.Dispatch<React.SetStateAction<boolean>>}) {
  let navigate = useNavigate();
  const [showUnauthWarn, setShowUnauthWarn] = useState<boolean>(false);
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then((result) => {
      // console.log(result.user.uid);
      // console.log(result);
      const doAuthorizeCheck = async () => {
        // do request to db for returned uid, check if user in table
        const authUsersCollectionRef = collection(db, "authUsers");
        getDoc(doc(authUsersCollectionRef, result.user.uid)).then((u: any) => {
          let d = u.data();
          if(d === undefined) {
            setDoc(doc(authUsersCollectionRef, result.user.uid), {
              authorized: false,
              attempts: 0,            
              uid: result.user.uid,
              displayName: result.user.displayName,
              email: result.user.email,
              metadata: {
                creationTime: result.user.metadata.creationTime,
                lastSignInTime: result.user.metadata.lastSignInTime,
              },
              phoneNumber: result.user.phoneNumber,
            })
            .then(() => {doAuthorizeCheck()}); // start over
          } else {
            // let d = querySnapshot.docs[0];
            // let newDoc = { ...d.data() };
            let newDoc = { ...u.data() };
            // if so, check if authorized param is true
            if(newDoc.authorized) {
              // if auth == true, do the localstorage add, setauth true, and navigate /
              localStorage.setItem("isAuth", "true");
              setIsAuth(true);
              navigate("/");
              return
            }
            // else do nothing or display dialog
            // increment attempts
            newDoc.attempts = newDoc.attempts + 1;
            newDoc.metadata.lastSignInTime = result.user.metadata.lastSignInTime;
            setDoc(doc(authUsersCollectionRef, result.user.uid), newDoc).then(() => {
              // cb(); // insert callback stuff
              setShowUnauthWarn(true);
            });
          }
        });
      }
      doAuthorizeCheck();
    });
  };

  useEffect(() => {
    if(isAuth){
      navigate("/");
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    logEvent(analytics, 'visit_cap_site_logon_page');
  }, [])

  return (
    <Page title={"Login"}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3" sx={{ textAlign: "center" }} gutterBottom>Sign in with Google</Typography>
        <Button variant="contained" onClick={signInWithGoogle}>Sign in with Google</Button>
      </Box>
      <Dialog
        open={showUnauthWarn}
        onClose={() => {}} // only perform close if button in dialog clicked
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Unauthorized Login Detected
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            This attempt has been logged.
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowUnauthWarn(false);
          }}>My Bad</Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default Login