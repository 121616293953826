import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArtSource, ArtSourceType } from "../interfaces";

function EditLink({ open, setOpen, oldLink, handleEditLink }: { open: boolean, setOpen: Function, oldLink: ArtSource, handleEditLink: Function }) {
  const [type, setType] = useState<ArtSourceType>(oldLink.type);
  const [link, setLink] = useState<string>(oldLink.link);
  const [customLabel, setCustomLabel] = useState<string|undefined>(oldLink.customLabel);
  const [sameTab, setSameTab] = React.useState<boolean|undefined>(oldLink.sameTab);
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newLink: ArtSource = {
      type: type,
      link: link
    };
    if(customLabel){
      newLink.customLabel = customLabel;
    }
    if(sameTab){
      newLink.sameTab = sameTab;
    }
    handleEditLink(oldLink, newLink);
    handleClose();
  };

  useEffect(() => {
    setType(oldLink.type);
    setLink(oldLink.link);
    setCustomLabel(oldLink.customLabel);
    setSameTab(oldLink.sameTab);
  }, [oldLink.link, oldLink.type, oldLink.customLabel, oldLink.sameTab, open]);

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as ArtSourceType);
  };

  const handleChangeSameTab = (event: { target: { checked: boolean | ((prevState: boolean | undefined) => boolean | undefined) | undefined; }; }) => {
    setSameTab(event.target.checked);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit Link
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FormControl fullWidth>
              <InputLabel id="simple-select-label">Type</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={type}
                label="Type"
                onChange={handleChangeType}
              >
                <MenuItem value={"Other Link"}>Other Link</MenuItem>
                <MenuItem value={"FurAffinity"}>FurAffinity</MenuItem>
                <MenuItem value={"Twitter"}>Twitter</MenuItem>
                <MenuItem value={"e621"}>e621</MenuItem>
                <MenuItem value={"Cap on Twitter"}>Cap on Twitter</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              id="new-link-url"
              label="URL"
              placeholder='https://twitter.com/'
              defaultValue={oldLink.link}
              onChange={(event) => { setLink(event.target.value) }}
            />
            <TextField
              required
              id="new-link-customLabel"
              label="Custom Label (optional)"
              placeholder='e.g., Alt Cap Version'
              defaultValue={oldLink.customLabel}
              onChange={(event) => { setCustomLabel(event.target.value) }}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox
                checked={sameTab}
                onChange={handleChangeSameTab}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Open in Same Tab When Clicked?" />
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditLink