import { Box, Button, Chip, Container, Fab, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Page from '../../Templates/Page'
import SortIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import { CaptionDataRow } from '../../interfaces';
import dayjs from 'dayjs';
import { addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import CaptionDialog from '../../Components/CaptionDialog';
import CaptionShare from '../../Components/CaptionShare';
import { Link } from 'react-router-dom';

const fabStyle = {
  position: "fixed",
  bottom: 16,
  right: 16,
};

interface CustomExpanderComponentProps
  extends ExpanderComponentProps<CaptionDataRow> {
  // currently, props that extend ExpanderComponentProps must be set to optional.
  isAuth?: boolean;
  runUpdate?: any; // doesn't like being Function
  captions?: Array<any>;
  setCaptions?: any; // doesn't like being Function
  viewCounts?: Array<any>;
}

const ExpandedComponent: React.FC<CustomExpanderComponentProps> = ({
  data,
  isAuth,
  captions,
  setCaptions,
  viewCounts,
  runUpdate,
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [loadedCapForEditing, setLoadedCapForEditing] = useState<CaptionDataRow>(data);
  const [views, setViews] = useState<Number>(-1);

  useEffect(() => {
    setLoadedCapForEditing(data);
    try{
      let viewObjs  = viewCounts?.find((i) => i.id === data.id);
      let viewCount = viewObjs.viewCount ? viewObjs.viewCount : -1;
      setViews(viewCount);
    } catch (err) {
      // do nothing for now
    }

  }, [data, viewCounts]);
  const editCapHandler = async (data: CaptionDataRow, cb: Function) => {
    const capDoc = doc(db, "caps", data.id);
    setDoc(capDoc, data).then(() => {
      cb();
    });
  };
  
  return (
    <>
      <Paper
        sx={{
          margin: "1em"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* {JSON.stringify(data)} */}
          <Box
            sx={{
              padding: "1em",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Typography variant="h5">{data.title}</Typography>
            {data.unlisted && <div><Chip label={"Unlisted"} /></div>}
            <Typography variant="caption">{data.description || "No Description"}</Typography>
            <Typography variant="caption">{views >= 0 ? `${views} views` : "Unknown View Count"}</Typography>
            <Typography variant="body1">{`Date Published: ${dayjs(parseInt(data.date, 10)).format()}`}</Typography>
            <Typography variant="body1">{`ID: ${data.id}`}</Typography>
            <CaptionShare data={data} />
            <div>{data.tags && data.tags.length > 0 && <div>Tags: {data.tags.map((tag: string, idx: number) => (<Tooltip key={`tag-${idx}`} title={`View caps tagged ${tag}`}><Link to={`/tagged/${encodeURIComponent(tag)}`}><Chip sx={{ cursor: "pointer" }} label={`#${tag}`} /></Link></Tooltip>))}</div>}</div>
            <img src={data.imageUrl} alt="caption" style={{ height: "250px", width: "auto", objectFit: "contain" }} />
          </Box>
          <Button onClick={() => { setEditOpen(true); }}>Edit</Button>
          <CaptionDialog open={editOpen} setOpen={setEditOpen} dialogTitle={"Edit Caption Post"} loadedCapForEditing={loadedCapForEditing} setLoadedCapForEditing={setLoadedCapForEditing} editCapHandler={editCapHandler} runUpdate={runUpdate} />
        </Box>
      </Paper>
    </>
  )
}

const columns: TableColumn<any>[] = [
  {
    name: "Caption",
    id: 'caption',
    selector: (r: CaptionDataRow) => r.id,
    cell: (r: CaptionDataRow) => `${r.title} - ${r.id}${r.unlisted ? " (unlisted)" : ""}`,
    sortable: false,
  },
  {
    name: "Date",
    id: 'date',
    selector: (r: CaptionDataRow) => r.date,
    cell: (r: CaptionDataRow) => `${dayjs(parseInt(r.date, 10)).format()}`,
    sortable: true,
  },
];

function ManageCaps({ isAuth, signUserOut }: { isAuth: boolean, signUserOut: Function }) {
  const [createNewOpen, setCreateNewOpen] = useState<boolean>(false);
  const [captions, setCaptions] = useState<Array<CaptionDataRow | any>>([]);
  const [pending, setPending] = useState<boolean>(true);
  const [viewCounts, setViewCounts] = useState<Array<any>>([]);
  // const [loadedCapForEditing, setLoadedCapForEditing] = useState<CaptionDataRow|undefined>();

  const addCapHandler = async (data: CaptionDataRow, cb: Function) => {
    const captionsCollectionRef = collection(db, "caps");
    addDoc(captionsCollectionRef, data).then(() => {
      cb();
    });
  };

  const runUpdate = () => {
    const getCaptions = async () => {
      const captionsCollectionRef = collection(db, "caps");
      const data = await getDocs(captionsCollectionRef);
      setCaptions(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setPending(false);
    }
    getCaptions();
    const getViewCounts = async () => {
      const viewCountsCollectionRef = collection(db, "viewCounts");
      const data = await getDocs(viewCountsCollectionRef);
      setViewCounts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    getViewCounts();
    // setPending(false);
  };

  useEffect(() => {
    runUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page isAuth={isAuth} signUserOut={signUserOut}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em"
        }}
      >
        <Button variant="contained" onClick={() => { setCreateNewOpen(true) }}>Create New Caption Post</Button>

        <Container>
          <DataTable
            title={"Manage Captions"}
            columns={columns}
            data={captions}
            sortIcon={<SortIcon />}
            defaultSortFieldId={'date'}
            striped
            defaultSortAsc={false}
            pagination
            selectableRows={false}
            expandOnRowClicked
            // expandableRows={isAuth}
            expandableRows={true}
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedComponent}
            expandableRowsComponentProps={{
              isAuth: isAuth,
              captions: captions,
              setCaptions: setCaptions,
              runUpdate: runUpdate,
              viewCounts: viewCounts,
            }}
            progressPending={pending}
          />
        </Container>
        {isAuth && (
          <Fab
            color="primary"
            aria-label="add"
            sx={{ ...fabStyle }}
            onClick={() => {
              setCreateNewOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        )}

      </Box>
      <CaptionDialog open={createNewOpen} setOpen={setCreateNewOpen} dialogTitle={"Create New Caption Post"} addCapHandler={addCapHandler} runUpdate={runUpdate} />
    </Page>
  )
}

export default ManageCaps